<template>
  <div id="app"
    class="privacy-policy page-template-default page page-id-3 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="en"/>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <article id="post-3" class="post-3 page type-page status-publish">
              <div class="inside-article">
                <BreadCrumb :breadcrumbs="breadcrumbs" />

                <header class="entry-header" aria-label="Content">
                  <h1 class="entry-title" itemprop="headline">
                    Contact Us
                  </h1>
                </header>

                <div class="entry-content" itemprop="text">
                  <p>
                    If you have any questions, feedback, or inquiries about GBWhatsApp, feel free to reach out to us. 
                  </p>
                  <p>
                    We'd love to hear from you!
                  </p>
                  <p>
                    <strong>Email: gbwhatsappchat@gmail.com</strong>
                    <br>
                    <strong>Website: <a href="https://www.gbwhatsapp.chat/" class="jump-url">www.gbwhatsapp.chat</a></strong>
                  </p>
                  <p>
                    We will try our best to respond within 24-48 hours.
                  </p>
                </div>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import HeadNav from '@/components/HeadNav.vue';
import Footer from '@/components/Footer.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';


export default {
  name: 'Privacy',
  components: {
    HeadNav,
    Footer,
    BreadCrumb,
  },
  data () {
    return {
      pageName: 'privacy',
    };
  },
  computed: {
    breadcrumbs() {
      return this.$route.matched.reduce((acc, route) => {
        const crumbs = route.meta.breadcrumb || [];
        return acc.concat(crumbs);
      }, []);
    }
  },
  mounted () {
    document.documentElement.lang = 'en';
   },
};
</script>
